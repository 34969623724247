import React from "react";

import styles from "./Details.module.css";

const Details = () => (
  <div className={styles.Details}>
    <div className={styles.DetailsIntro}>
      <div className={styles.DetailsIntroTitle}>
        How does{" "}
        <span className={styles.DetailsIntroTitleHighlighted}>FitLab</span>{" "}
        work?
      </div>
      <div className={styles.DetailsIntroText}>
        FitLab is a robust yet simple to use digital platform that covers most
        of daily tasks, build workouts, track clients progress, and manage
        nutrition plans.
      </div>
    </div>
    <div className={styles.DetailsBlocks}>
      <div className={styles.DetailsBlockRight}>
        <div className={styles.DetailsBlockInfo}>
          <div className={styles.DetailsBlockInfoTitle}>Workout builder</div>
          <div className={styles.DetailsBlockInfoText}>
            Create personalized workouts for each individual using our slick and
            easy-to-use workout builder.
          </div>
        </div>
        <div className={styles.DetailsBlockImgHolder}>
          <div className={styles.DetailsBlockImg1} />
        </div>
      </div>
      <div className={styles.DetailsBlockLeft}>
        <div className={styles.DetailsBlockImgHolder}>
          <div className={styles.DetailsBlockImg2} />
        </div>
        <div className={styles.DetailsBlockInfo}>
          <div className={styles.DetailsBlockInfoTitle}>Excercises Tracker</div>
          <div className={styles.DetailsBlockInfoText}>
            Check exercise performance and keep all history in one place.
          </div>
        </div>
      </div>
      <div className={styles.DetailsBlockRight}>
        <div className={styles.DetailsBlockInfo}>
          <div className={styles.DetailsBlockInfoTitle}>
            Client progress tracker
          </div>
          <div className={styles.DetailsBlockInfoText}>
            Get real-time status updates for each client’s workouts so you can
            motivate them to stay on track. Also, it helps to recognize how
            routines work.
          </div>
        </div>
        <div className={styles.DetailsBlockImgHolder}>
          <div className={styles.DetailsBlockImg3} />
        </div>
      </div>
      <div className={styles.DetailsBlockLeft}>
        <div className={styles.DetailsBlockImgHolder}>
          <div className={styles.DetailsBlockImg4} />
        </div>
        <div className={styles.DetailsBlockInfo}>
          <div className={styles.DetailsBlockInfoTitle}>
            Customization Toolkit
          </div>
          <div className={styles.DetailsBlockInfoText}>
            Get the power of customization in your hands! Now, you could define
            your exercises, warm-ups, attach video tutorials, and even more!
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Details;
